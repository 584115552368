import React from "react";

interface HeaderInterface {
  // Define your interface properties here
}

const Header: React.FC<HeaderInterface> = () => {
  return (
    <header className='header-section'>
      <div className='container'>
        <div className='logo'>
          <h5>Marching Frogs Events</h5>
        </div>
        <div className='nav-menu'>
          <nav className='mainmenu mobile-menu'>
            <ul>
              <li className='active'>
                <a href='/'>Home</a>
              </li>
              <li>
                <a href='/about-us'>About</a>
              </li>
              <li>
                <a href='/speaker'>Speakers</a>
                <ul className='dropdown'>
                  <li>
                    <a href='#'>Jayden</a>
                  </li>
                  <li>
                    <a href='#'>Sara</a>
                  </li>
                  <li>
                    <a href='#'>Emma</a>
                  </li>
                  <li>
                    <a href='#'>Harriet</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href='/schedule'>Schedule</a>
              </li>
              <li>
                <a href='/register'>Register</a>
              </li>
              <li>
                <a href='/contact'>Contacts</a>
              </li>
            </ul>
          </nav>
          <a href='#' className='primary-btn top-btn'>
            <i className='fa fa-ticket'></i> Ticket
          </a>
        </div>
        <div id='mobile-menu-wrap'></div>
      </div>
    </header>
  );
};

export default Header;
