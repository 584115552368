import React from "react";

interface indexInterface {
  // Define your interface properties here
}

const PrivacyPolicy: React.FC<indexInterface> = () => {
  return (
    <div className='max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg'>
      <h1 className='text-2xl font-bold mb-6'>Privacy Policy</h1>

      <section className='mb-8'>
        <p className='mb-4'>
          At <span className='font-semibold'>&quot;Marching Frogs,&quot;</span>{" "}
          our dedication to protecting and honoring your privacy runs deep
          within the very core of our being. We handle personal information
          about our cherished customers and those who show interest in our
          offerings with the utmost care and respect. Our commitment to
          safeguarding your privacy is unwavering.
        </p>
        <p className='mb-4'>
          This privacy policy serves as our promise to you, detailing the
          information we process, how it may impact you, and the rights you hold
          dear. The heart of{" "}
          <span className='font-semibold'>&quot;Marching Frogs&quot;</span>{" "}
          beats with the rhythm of transparency and honesty. We pledge to keep
          you informed, to walk hand in hand with you on this journey of trust.
        </p>
      </section>

      <section className='mb-8'>
        <p className='mb-4'>
          As we evolve and grow, so too does our privacy policy. With every step
          forward, we will share our progress with you, ensuring that you are
          kept in the light. Whenever changes are made, we will not only notify
          you through our website but will also reach out to you directly. Your
          well-being and peace of mind are paramount to us.
        </p>
      </section>

      <section className='mb-8'>
        <p className='mb-4'>
          In times of uncertainty or concern, know that our Data Protection
          Officers stand ready to listen, to reassure, and to guide. Contact
          them at{" "}
          <a
            href='mailto:info@marchingfrogsevents.com'
            className='text-blue-600 underline'
          >
            info@marchingfrogsevents.com
          </a>
          , and feel the warmth and passion that drives us to protect what
          matters most - you.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
