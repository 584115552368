import React from "react";

interface TermsAndConditionInterface {
  // Define your interface properties here
}

const TermsAndCondition: React.FC<TermsAndConditionInterface> = () => {
  return (
    <div className='max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg mb-6'>
      <h1 className='text-2xl font-bold mb-6 text-center underline'>Terms & Condition</h1>

      <section className='mb-8'>
        <h2 className='text-xl font-semibold mb-4'>Agreement Overview</h2>
        <p className='mb-2'>
          This Agreement is between Marching Frogs ("WE") and YOU. By using our
          services, you acknowledge that you have read, understood, and agree to
          be bound by the terms, and that you will comply with all applicable
          laws and regulations.
        </p>
        <p className='mb-2'>
          We encourage you to review and comply with proprietary notices and
          copyright information. Please note that there may be potential errors
          in the information provided on the website. We reserve the right to
          modify these terms without prior notice.
        </p>
        <p className='mb-2'>
          These terms and conditions are related to the services provided by
          Marching Frogs. You are required to review and understand these terms
          before utilizing our services. By using our services, you acknowledge
          complete understanding and acceptance of these terms and conditions.
        </p>
        <p className='mb-2'>
          We disclaim any reliance on statements outside of what is expressly
          detailed in this Agreement.
        </p>
      </section>

      <section className='mb-8'>
        <h2 className='text-xl font-semibold mb-4'>
          Linking to Other Websites
        </h2>
        <p className='mb-2'>
          We do not make any representations regarding other websites accessed
          through our website. These websites are independent of us, and we have
          no control over their contents, images, or any other material. We are
          not liable for any information or services provided by the owner of
          the linked website.
        </p>
        <p className='mb-2'>
          Our endorsement or responsibility for the linked website is not
          implied. We do not assume liability for any losses, damages, or claims
          arising from harmful elements on the linked website. Visitors and
          users should exercise caution when using the linked website.
        </p>
        <p className='mb-2'>
          We disclaim any and all liability for the contents, images, or
          materials on the linked website.
        </p>
      </section>

      <section className='mb-8'>
        <h2 className='text-xl font-semibold mb-4'>Copyrights</h2>
        <p className='mb-2'>
          Materials provided on this website are protected by copyright.
          Unauthorized copying, reproduction, distribution, republication,
          downloading, display, posting, or transmission of materials is
          strictly prohibited without written permission.
        </p>
        <p className='mb-2'>
          Visitors and users are permitted to download one copy of materials for
          personal or internal business use only. We reserve the right to revoke
          permission to download materials in case of breach of terms.
        </p>
        <p className='mb-2'>
          Replicating or mirroring any material without express written consent
          is prohibited. Content shared by the organization is subject to
          exclusive copyright protection. Unauthorized use of materials may
          infringe upon copyright, trademark, privacy, and communication laws.
        </p>
        <p className='mb-2'>
          All content and functionality on the website are the exclusive
          property of the organization or its licensors and are protected by
          Indian copyright law.
        </p>
      </section>

      <section className='mb-8'>
        <h2 className='text-xl font-semibold mb-4'>Trademarks</h2>
        <p className='mb-2'>
          Trademarks utilized and exhibited on websites are both registered and
          unregistered trademarks of Marching Frogs. Other trademarks not solely
          owned by us might be the property of others.
        </p>
        <p className='mb-2'>
          No license or privilege is granted to use any trademark or
          intellectual property on these websites. We vigorously protect our
          intellectual property rights.
        </p>
        <p className='mb-2'>
          Usage of "Marching Frogs" and other trademarks is strictly prohibited
          without written consent. Integration of trademarks as part of a link
          requires pre-approval in written form.
        </p>
      </section>

      <section className='mb-8'>
        <h2 className='text-xl font-semibold mb-4'>Use of Website Content</h2>
        <h3 className='text-lg font-semibold mb-2'>User Postings</h3>
        <p className='mb-2'>
          Users acknowledge and agree that the website shall own and have the
          unrestricted right to use, publish, distribute, and exploit any
          information that users post on the website. Users waive any and all
          claims against the website for alleged or actual infringements of
          privacy rights, publicity rights, moral rights, rights of attribution,
          or any other intellectual property rights in connection with the use
          and publication of such submissions posted on the website.
        </p>
        <p className='mb-2'>
          Users grant the website a perpetual, royalty-free, non-exclusive,
          sub-licensable, unrestricted, unlimited, worldwide, and irrevocable
          right and license to use, reproduce, modify, publish, translate,
          prepare derivative works, distribute, perform, or display such
          submissions.
        </p>
        <p className='mb-2'>
          The website does not represent or endorse and is not responsible for
          the accuracy or reliability of any submissions displayed, uploaded, or
          posted on any message board or otherwise distributed through the
          website.
        </p>
        <p className='mb-2'>
          The website expressly disclaims any and all liability related to
          submissions, and any reliance upon such submissions by visitors shall
          be at their sole discretion, liability, and risk.
        </p>
      </section>
    </div>
  );
};

export default TermsAndCondition;
