import React from "react";

interface AboutInterface {
  // Define your interface properties here
}

const About: React.FC<AboutInterface> = () => {
  const members = [
    "member-1.jpg",
    "member-2.jpg",
    "member-3.jpg",
    "member-4.jpg",
    "member-5.jpg",
    "member-6.jpg",
    "member-7.jpg",
    "member-8.jpg",
    "member-9.jpg",
    "member-10.jpg",
  ];

  const renderMembers = () =>
    members.map((member, index) => (
      <div
        key={index}
        className='member-item set-bg'
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/img/team-member/${member})`,
        }}
      >
        <div className='mi-social'>
          <div className='mi-social-inner bg-gradient'>
            <a href='#'>
              <i className='fa fa-facebook'></i>
            </a>
            <a href='#'>
              <i className='fa fa-instagram'></i>
            </a>
            <a href='#'>
              <i className='fa fa-twitter'></i>
            </a>
            <a href='#'>
              <i className='fa fa-linkedin'></i>
            </a>
          </div>
        </div>
        <div className='mi-text'>
          <h5>Emma Sandoval</h5>
          <span>Speaker</span>
        </div>
      </div>
    ));
  return (
    <>
      <section className='breadcrumb-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='breadcrumb-text'>
                <h2>About Us</h2>
                <div className='bt-option'>
                  <a href='#'>Home</a>
                  <span>About</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='about-section spad'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='section-title'>
                <h2>Something About Us</h2>
                <p className='f-para'>
                  Marching Frogs is a global network of legal professionals who
                  specialize in the legal industry. Our platform is dedicated to
                  providing support, insights, and connections for individuals
                  involved in legal matters. We offer a space for legal
                  professionals to engage, connect, and promote business
                  opportunities, while also recognizing their contributions to
                  the profession. Our members come from a variety of
                  backgrounds, including law firms, corporations, universities,
                  and venture capitalists, all with a shared passion for the
                  practice of law.
                </p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='about-pic'>
                <img src='img/about-us.jpg' alt='' />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='ha-text'>
                <h2>About Conference</h2>
                <p>
                  Marching Frogs is a global network of legal professionals who
                  specialize in the legal industry. Our platform is dedicated to
                  providing support, insights, and connections for individuals
                  involved in legal matters. We offer a space for legal
                  professionals to engage, connect, and promote business
                  opportunities, while also recognizing their contributions to
                  the profession. Our members come from a variety of
                  backgrounds, including law firms, corporations, universities,
                  and venture capitalists, all with a shared passion for the
                  practice of law.
                </p>
                <ul>
                  <li>
                    <span className='icon_check'></span> Marching Frogs is a
                    network of Legal Professionals.
                  </li>
                  <li>
                    <span className='icon_check'></span> AProvides timely,
                    relevant, and unique events Globally.
                  </li>
                  <li>
                    <span className='icon_check'></span> Helps connect with the
                    right knowledge, ideas, people, marketplaces, and
                    opportunities.
                  </li>
                  <li>
                    <span className='icon_check'></span> Legal counsel advice
                    intends to cover every aspect of legal matters globally.
                  </li>
                  <li>
                    <span className='icon_check'></span> Aims to provide the
                    best legal platform in the world
                  </li>
                </ul>
                <a href='#' className='ha-btn'>
                  Discover Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='team-member-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='section-title'>
                <h2>Who’s speaking</h2>
                <p>
                  These are our communicators, you can see each person's
                  information.
                </p>
              </div>
            </div>
          </div>
        </div>
        {renderMembers()}
      </section>

      <section className='story-section spad'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='section-title'>
                <h2>Our Story</h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='story-left'>
                <div className='story-item'>
                  <h2>2008</h2>
                  <div className='si-text'>
                    <h4>Adwords Keyword Research For Beginners</h4>
                    <p>
                      However this is also the most expensive position. Give it
                      a try if the second to fourth display position gives you
                      more visitors and more customers for less money.
                    </p>
                  </div>
                </div>
                <div className='story-item'>
                  <h2>2011</h2>
                  <div className='si-text'>
                    <h4>Adwords Keyword Research For Beginners</h4>
                    <p>
                      Virgin Mobile took a more effective approach in marketing
                      their cell phone service by focusing not on the people
                      that would be making the actual purchase.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='story-right'>
                <div className='story-item'>
                  <h2>2015</h2>
                  <div className='si-text'>
                    <h4>15 Tips To Increase Your Adwords Profits</h4>
                    <p>
                      There is no better advertisement campaign that is low cost
                      and also successful at the same time. Great business ideas
                      when utilized effectively can save lots of money.
                    </p>
                  </div>
                </div>
                <div className='story-item'>
                  <h2>2019</h2>
                  <div className='si-text'>
                    <h4>
                      Advertising Internet Online Opportunities To Explore
                    </h4>
                    <p>
                      Many people sign up for affiliate programs with the hopes
                      of making some serious money. They advertise a few places
                      and then wait for the money to start pouring in.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className='pricing-section set-bg spad'
        data-setbg='img/pricing-bg.jpg'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='section-title'>
                <h2>Ticket Pricing</h2>
                <p>Get your event ticket plan</p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='price-item'>
                <h4>1 Day Pass</h4>
                <div className='pi-price'>
                  <h2>
                    <span>$</span>129
                  </h2>
                </div>
                <ul>
                  <li>One Day Conference Ticket</li>
                  <li>Coffee-break</li>
                  <li>Lunch and Networking</li>
                  <li>Keynote talk</li>
                  <li>Talk to the Editors Session</li>
                </ul>
                <a href='#' className='price-btn'>
                  Get Ticket <span className='arrow_right'></span>
                </a>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='price-item top-rated'>
                <div className='tr-tag'>
                  <i className='fa fa-star'></i>
                </div>
                <h4>Full Pass</h4>
                <div className='pi-price'>
                  <h2>
                    <span>$</span>199
                  </h2>
                </div>
                <ul>
                  <li>One Day Conference Ticket</li>
                  <li>Coffee-break</li>
                  <li>Lunch and Networking</li>
                  <li>Keynote talk</li>
                  <li>Talk to the Editors Session</li>
                  <li>Lunch and Networking</li>
                  <li>Keynote talk</li>
                </ul>
                <a href='#' className='price-btn'>
                  Get Ticket <span className='arrow_right'></span>
                </a>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='price-item'>
                <h4>Group Pass</h4>
                <div className='pi-price'>
                  <h2>
                    <span>$</span>79
                  </h2>
                </div>
                <ul>
                  <li>One Day Conference Ticket</li>
                  <li>Coffee-break</li>
                  <li>Lunch and Networking</li>
                  <li>Keynote talk</li>
                  <li>Talk to the Editors Session</li>
                </ul>
                <a href='#' className='price-btn'>
                  Get Ticket <span className='arrow_right'></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='testimonial-section spad'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='section-title'>
                <h2>Testimonials</h2>
                <p>
                  Our customers are our biggest supporters. What do they think
                  of us?
                </p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='row'>
                <div className='testimonial-slider owl-carousel'>
                  <div className='col-lg-6'>
                    <div className='testimonial-item'>
                      <div className='ti-author'>
                        <div className='quote-pic'>
                          <img src='img/quote.png' alt='' />
                        </div>
                        <div className='ta-pic'>
                          <img src='img/testimonial/testimonial-1.jpg' alt='' />
                        </div>
                        <div className='ta-text'>
                          <h5>Emma Sandoval</h5>
                          <span>Speaker Manager</span>
                        </div>
                      </div>
                      <p>
                        “First impression is made by logo or its absence. To
                        know the importance of a logo just answer one question:
                        How many big, leading and famous companies don’t have
                        logos?”
                      </p>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='testimonial-item'>
                      <div className='ti-author'>
                        <div className='quote-pic'>
                          <img src='img/quote.png' alt='' />
                        </div>
                        <div className='ta-pic'>
                          <img src='img/testimonial/testimonial-2.jpg' alt='' />
                        </div>
                        <div className='ta-text'>
                          <h5>John Smith</h5>
                          <span>Speaker Manager</span>
                        </div>
                      </div>
                      <p>
                        “There is no denying the fact that the success of an
                        advertisement lies mostly in the headline. The headline
                        should attract the reader and make him read the rest of
                        the advertisement.”
                      </p>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='testimonial-item'>
                      <div className='ti-author'>
                        <div className='quote-pic'>
                          <img src='img/quote.png' alt='' />
                        </div>
                        <div className='ta-pic'>
                          <img src='img/testimonial/testimonial-2.jpg' alt='' />
                        </div>
                        <div className='ta-text'>
                          <h5>John Smith</h5>
                          <span>Speaker Manager</span>
                        </div>
                      </div>
                      <p>
                        “There is no denying the fact that the success of an
                        advertisement lies mostly in the headline. The headline
                        should attract the reader and make him read the rest of
                        the advertisement.”
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='newslatter-section about-newslatter'>
        <div className='container'>
          <div
            className='newslatter-inner set-bg'
            data-setbg='img/newslatter-bg.jpg'
          >
            <div className='ni-text'>
              <h3>Subscribe Newsletter</h3>
              <p>Subscribe to our newsletter and don’t miss anything</p>
            </div>
            <form action='#' className='ni-form'>
              <input type='text' placeholder='Your email' />
              <button type='submit'>Subscribe</button>
            </form>
          </div>
        </div>
      </section>

      <section className='contact-section spad'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='section-title'>
                <h2>Location</h2>
                <p>Get directions to our event center</p>
              </div>
              <div className='cs-text'>
                <div className='ct-address'>
                  <span>Address:</span>
                  <p>
                    01 Pascale Springs Apt. 339, NY City <br />
                    United State
                  </p>
                </div>
                <ul>
                  <li>
                    <span>Phone:</span>
                    (+12)-345-67-8910
                  </li>
                  <li>
                    <span>Email:</span>
                    info.colorlib@gmail.com
                  </li>
                </ul>
                <div className='ct-links'>
                  <span>Website:</span>
                  <p>https://conference.colorlib.com</p>
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='cs-map'>
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d52901.38789495531!2d-118.19465514866786!3d34.03523211493029!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2cf71ad83ff9f%3A0x518b28657f4543b7!2sEast%20Los%20Angeles%2C%20CA%2C%20USA!5e0!3m2!1sen!2sbd!4v1579763856144!5m2!1sen!2sbd'
                  height='400'
                  style={{ border: "0" }}
                  // allowfullscreen=""
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
