import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Header from "./components/Header";
import Footer from "./components/Footer";
import TermsAndCondition from "./components/TermsAndCondition";
import RefundPolicy from "./components/RefundPolicy";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Register from "./components/Register";
import Schedule from "./components/Schedule";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about-us' element={<About />} />
        <Route path='/register' element={<Register />} />
        <Route path='/schedule' element={<Schedule />} />
        <Route path='/terms-condition' element={<TermsAndCondition />} />
        <Route path='/refund-policy' element={<RefundPolicy />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
