import React from "react";

interface indexInterface {
  // Define your interface properties here
}

const RefundPolicy: React.FC<indexInterface> = () => {
  return (
    <div className='max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg'>
      <h1 className='text-2xl font-bold mb-6 text-center'>Refund/Cancellation Policy</h1>

      <section className='mb-8'>
        <h2 className='text-xl font-semibold mb-4'>
          Nomination Fee Refund Policy
        </h2>
        <p className='mb-2'>
          In the event that a nominee does not win the award, a refund of their
          nomination fee will be provided. However, it is important to note that
          no refund will be issued if the eventual winner chooses to decline
          acceptance of the award.
        </p>
      </section>

      <section className='mb-8'>
        <h2 className='text-xl font-semibold mb-4'>
          Attendance or Sponsorship Modifications
        </h2>
        <p className='mb-2'>
          Requests for cancellations must be made within 15 days of the payment
          date. A 10% administrative fee will be deducted for all cancellations.
        </p>
        <p className='mb-2'>
          The refund policy is designed to protect both our attendees and our
          organization in the event of unforeseen circumstances. We understand
          that life can be unpredictable, and we strive to offer a fair solution
          to those who may need to cancel their attendance at our event.
        </p>
        <p className='mb-2'>
          If, for any reason, you find yourself needing to cancel your
          participation, please know that we are here to support you. We offer a
          generous 90% refund if you cancel within the first 15 days of your
          payment. We want to show our appreciation for your early commitment to
          us.
        </p>
        <p className='mb-2'>
          However, we also understand that sometimes things happen beyond our
          control. If you cancel after the 15-day window, unfortunately, we will
          be unable to provide a refund. This is not to cause any hardship, but
          to ensure the sustainability of our event.
        </p>
        <p className='mb-2'>
          In the event that we, the organizers, have to cancel the event, you
          can rest assured that you will receive a full refund. Your trust and
          support mean everything to us, and we want to honor that in any way we
          can.
        </p>
        <p className='mb-2'>
          We also want to acknowledge that sometimes external factors can come
          into play. Natural disasters, government policy changes, or even a
          worldwide pandemic can impact our ability to hold the event as
          planned. In these cases, we will not be able to provide refunds, as we
          must prioritize the safety and well-being of our attendees and staff.
        </p>
        <p className='mb-2'>
          Please understand that visa rejections or travel restrictions are also
          beyond our control. While we wish we could assist in these situations,
          we must maintain our policy of no refunds in these cases. We trust
          that our attendees, participants, and speakers will take
          responsibility for their own travel and visa arrangements.
        </p>
        <p className='mb-2'>
          If you do need to cancel, please reach out to us via email with your
          billing information and a brief explanation of your refund request. We
          promise to process your refund as quickly as possible, typically
          within 15 days of receiving your email.
        </p>
      </section>

      <section className='mb-8'>
        <p className='mb-2'>
          This policy is in place to protect all parties involved and to ensure
          the smooth operation of our event. We appreciate your understanding
          and cooperation in these matters. Thank you for being a part of our
          community, and we look forward to seeing you at our event.
        </p>
      </section>
    </div>
  );
};

export default RefundPolicy;
