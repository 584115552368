import React from "react";

interface HomeInterface {
  // Define your interface properties here
}

const Home: React.FC<HomeInterface> = () => {
  const sessions = [
    {
      id: 1,
      title:
        "Litigation: Enforcement of Foreign Judgments in the Middle East Reason",
    },
    { id: 2, title: "Does Corporate Matter, Problems with Outsourcing" },
    {
      id: 3,
      title:
        "The Legal Challenges And Disputes That Arise In The Context Of Infrastructure Development Projects",
    },
    {
      id: 4,
      title:
        "The Legal Issues Of The Metaverse And NFTs - The Good, The Brag, And The Ugly",
    },
    {
      id: 5,
      title:
        "Strategies For Managing Litigation Risk, Including Insurance, Litigation Financing, And Early Case Assessment",
    },
    {
      id: 6,
      title:
        "Digital Transformation Technologies Of Legal Proceedings In Civil And Administrative Cases",
    },
    {
      id: 7,
      title:
        "The Use Of AI In Litigation: Legal And Ethical Concerns, For Example Bias In AI Algorithms",
    },
    {
      id: 8,
      title:
        "The Recent Reforms To The Saudi Legal System And Their Impact On Litigation",
    },
    {
      id: 9,
      title:
        "ADR (Alternative Dispute Resolution) And Litigation - Two Different Approaches To Resolving Legal Disputes",
    },
    {
      id: 10,
      title:
        "The Arbitral Process And Alternative Dispute Resolution In The EMEA Region",
    },
    { id: 11, title: "Diversity, Equity, and Inclusion in Litigation" },
    {
      id: 12,
      title: "Future Trends in Commercial and Investment Arbitration",
    },
  ];
  const members = [
    "member-1.jpg",
    "member-2.jpg",
    "member-3.jpg",
    "member-4.jpg",
    "member-5.jpg",
    "member-6.jpg",
    "member-7.jpg",
    "member-8.jpg",
    "member-9.jpg",
    "member-10.jpg",
  ];

  const renderMembers = () =>
    members.map((member, index) => (
      <div
        key={index}
        className='member-item set-bg'
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/img/team-member/${member})`,
        }}
      >
        <div className='mi-social'>
          <div className='mi-social-inner bg-gradient'>
            <a href='#'>
              <i className='fa fa-facebook'></i>
            </a>
            <a href='#'>
              <i className='fa fa-instagram'></i>
            </a>
            <a href='#'>
              <i className='fa fa-twitter'></i>
            </a>
            <a href='#'>
              <i className='fa fa-linkedin'></i>
            </a>
          </div>
        </div>
        <div className='mi-text'>
          <h5>Emma Sandoval</h5>
          <span>Speaker</span>
        </div>
      </div>
    ));
  return (
    <>
      <section
        className='hero-section set-bg'
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/img/hero.jpg)`,
        }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7'>
              <div className='hero-text'>
                <span>9 & 10 April 2025, Riyaad hotel, Riyaad</span>
                <h2>International Legal Symposium</h2>
                <a href='#' className='primary-btn'>
                  Buy Ticket
                </a>
              </div>
            </div>
            <div className='col-lg-5'>
              <img src='img/hero-right.png' alt='' />
            </div>
          </div>
        </div>
      </section>
      <section className='counter-section bg-gradient'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='counter-text'>
                <span>Conference Date</span>
                <h3>
                  Count Every Second <br />
                  Until the Event
                </h3>
              </div>
            </div>
            <div className='col-lg-8'>
              <div className='cd-timer' id='countdown'>
                <div className='cd-item'>
                  <span>40</span>
                  <p>Days</p>
                </div>
                <div className='cd-item'>
                  <span>18</span>
                  <p>Hours</p>
                </div>
                <div className='cd-item'>
                  <span>46</span>
                  <p>Minutes</p>
                </div>
                <div className='cd-item'>
                  <span>32</span>
                  <p>Seconds</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='home-about-section spad'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='ha-pic'>
                <img src='img/h-about.jpg' alt='' />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='ha-text'>
                <h2>About Conference</h2>
                <p>
                  Marching Frogs is a global network of legal professionals who
                  specialize in the legal industry. Our platform is dedicated to
                  providing support, insights, and connections for individuals
                  involved in legal matters. We offer a space for legal
                  professionals to engage, connect, and promote business
                  opportunities, while also recognizing their contributions to
                  the profession. Our members come from a variety of
                  backgrounds, including law firms, corporations, universities,
                  and venture capitalists, all with a shared passion for the
                  practice of law.
                </p>
                <ul>
                  <li>
                    <span className='icon_check'></span> Marching Frogs is a
                    network of Legal Professionals.
                  </li>
                  <li>
                    <span className='icon_check'></span> AProvides timely,
                    relevant, and unique events Globally.
                  </li>
                  <li>
                    <span className='icon_check'></span> Helps connect with the
                    right knowledge, ideas, people, marketplaces, and
                    opportunities.
                  </li>
                  <li>
                    <span className='icon_check'></span> Legal counsel advice
                    intends to cover every aspect of legal matters globally.
                  </li>
                  <li>
                    <span className='icon_check'></span> Aims to provide the
                    best legal platform in the world
                  </li>
                </ul>
                <a href='#' className='ha-btn'>
                  Discover Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='team-member-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='section-title'>
                <h2>Who’s speaking</h2>
                <p>
                  These are our communicators, you can see each person's
                  information.
                </p>
              </div>
            </div>
          </div>
        </div>
        {renderMembers()}
      </section>
      <section className='schedule-section spad'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='section-title'>
                <h2>Our Schedule</h2>
                <p>Do not miss anything topic about the event</p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='schedule-tab'>
                <ul className='nav nav-tabs grid grid-cols-2' role='tablist'>
                  <li className='nav-item w-full text-center'>
                    <a
                      className='nav-link active'
                      data-toggle='tab'
                      href='#tabs-1'
                      role='tab'
                    >
                      <h5>Day 1</h5>
                      <p>March 04, 2025</p>
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      data-toggle='tab'
                      href='#tabs-2'
                      role='tab'
                    >
                      <h5>Day 2</h5>
                      <p>March 05, 2025</p>
                    </a>
                  </li>
                </ul>
                <div className='tab-content'>
                  <div
                    className='max-w-6xl mx-auto p-8 bg-gray-100 rounded-lg shadow-lg tab-pane'
                    id='tabs-1'
                    role='tabpanel'
                  >
                    <h1 className='text-3xl font-bold text-center mb-8'>
                      Agenda 2025 - March
                    </h1>
                    <table className='min-w-full bg-white rounded-lg'>
                      <thead>
                        <tr>
                          <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                            Session
                          </th>
                          <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                            Topic
                          </th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        {sessions.map((session) => (
                          <tr key={session.id} className='hover:bg-gray-100'>
                            <td className='px-6 py-4 border-b border-gray-200 text-sm font-medium text-gray-900'>
                              {`Session ${session.id}`}
                            </td>
                            <td className='px-6 py-4 border-b border-gray-200 text-sm text-gray-700'>
                              {session.title}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className='max-w-6xl mx-auto p-8 bg-gray-100 rounded-lg shadow-lg tab-pane'
                    id='tabs-2'
                    role='tabpanel'
                  >
                    <h1 className='text-3xl font-bold text-center mb-8'>
                      Agenda 2025 - March
                    </h1>
                    <table className='min-w-full bg-white rounded-lg'>
                      <thead>
                        <tr>
                          <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                            Session
                          </th>
                          <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                            Topic
                          </th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        {sessions.map((session) => (
                          <tr key={session.id} className='hover:bg-gray-100'>
                            <td className='px-6 py-4 border-b border-gray-200 text-sm font-medium text-gray-900'>
                              {`Session ${session.id}`}
                            </td>
                            <td className='px-6 py-4 border-b border-gray-200 text-sm text-gray-700'>
                              {session.title}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className='tab-pane' id='tabs-3' role='tabpanel'>
                    <div className='st-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <div className='sc-pic'>
                              <img src='img/schedule/schedule-1.jpg' alt='' />
                            </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='sc-text'>
                              <h4>Dealing with Intermediate People</h4>
                              <ul>
                                <li>
                                  <i className='fa fa-user'></i> John Smith
                                </li>
                                <li>
                                  <i className='fa fa-envelope'></i>{" "}
                                  john@Colorlib.com
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <ul className='sc-widget'>
                              <li>
                                <i className='fa fa-clock-o'></i> 08:00 am -
                                10:00 AM
                              </li>
                              <li>
                                <i className='fa fa-map-marker'></i> 59 Breanne
                                Canyon Suite, USA
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='st-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <div className='sc-pic'>
                              <img src='img/schedule/schedule-2.jpg' alt='' />
                            </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='sc-text'>
                              <h4>V7 Digital Photo Printing</h4>
                              <ul>
                                <li>
                                  <i className='fa fa-user'></i> John Smith
                                </li>
                                <li>
                                  <i className='fa fa-envelope'></i>{" "}
                                  john@Colorlib.com
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <ul className='sc-widget'>
                              <li>
                                <i className='fa fa-clock-o'></i> 08:00 am -
                                10:00 AM
                              </li>
                              <li>
                                <i className='fa fa-map-marker'></i> 59 Breanne
                                Canyon Suite, USA
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='st-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <div className='sc-pic'>
                              <img src='img/schedule/schedule-3.jpg' alt='' />
                            </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='sc-text'>
                              <h4>Beyond The Naked Eye</h4>
                              <ul>
                                <li>
                                  <i className='fa fa-user'></i> John Smith
                                </li>
                                <li>
                                  <i className='fa fa-envelope'></i>{" "}
                                  john@Colorlib.com
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <ul className='sc-widget'>
                              <li>
                                <i className='fa fa-clock-o'></i> 08:00 am -
                                10:00 AM
                              </li>
                              <li>
                                <i className='fa fa-map-marker'></i> 59 Breanne
                                Canyon Suite, USA
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='st-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <div className='sc-pic'>
                              <img src='img/schedule/schedule-4.jpg' alt='' />
                            </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='sc-text'>
                              <h4>Influencing The Influencer</h4>
                              <ul>
                                <li>
                                  <i className='fa fa-user'></i> John Smith
                                </li>
                                <li>
                                  <i className='fa fa-envelope'></i>{" "}
                                  john@Colorlib.com
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <ul className='sc-widget'>
                              <li>
                                <i className='fa fa-clock-o'></i> 08:00 am -
                                10:00 AM
                              </li>
                              <li>
                                <i className='fa fa-map-marker'></i> 59 Breanne
                                Canyon Suite, USA
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-pane' id='tabs-4' role='tabpanel'>
                    <div className='st-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <div className='sc-pic'>
                              <img src='img/schedule/schedule-1.jpg' alt='' />
                            </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='sc-text'>
                              <h4>Dealing with Expert People</h4>
                              <ul>
                                <li>
                                  <i className='fa fa-user'></i> John Smith
                                </li>
                                <li>
                                  <i className='fa fa-envelope'></i>{" "}
                                  john@Colorlib.com
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <ul className='sc-widget'>
                              <li>
                                <i className='fa fa-clock-o'></i> 08:00 am -
                                10:00 AM
                              </li>
                              <li>
                                <i className='fa fa-map-marker'></i> 59 Breanne
                                Canyon Suite, USA
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='st-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <div className='sc-pic'>
                              <img src='img/schedule/schedule-2.jpg' alt='' />
                            </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='sc-text'>
                              <h4>V7 Digital Photo Printing</h4>
                              <ul>
                                <li>
                                  <i className='fa fa-user'></i> John Smith
                                </li>
                                <li>
                                  <i className='fa fa-envelope'></i>{" "}
                                  john@Colorlib.com
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <ul className='sc-widget'>
                              <li>
                                <i className='fa fa-clock-o'></i> 08:00 am -
                                10:00 AM
                              </li>
                              <li>
                                <i className='fa fa-map-marker'></i> 59 Breanne
                                Canyon Suite, USA
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='st-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <div className='sc-pic'>
                              <img src='img/schedule/schedule-3.jpg' alt='' />
                            </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='sc-text'>
                              <h4>Beyond The Naked Eye</h4>
                              <ul>
                                <li>
                                  <i className='fa fa-user'></i> John Smith
                                </li>
                                <li>
                                  <i className='fa fa-envelope'></i>{" "}
                                  john@Colorlib.com
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <ul className='sc-widget'>
                              <li>
                                <i className='fa fa-clock-o'></i> 08:00 am -
                                10:00 AM
                              </li>
                              <li>
                                <i className='fa fa-map-marker'></i> 59 Breanne
                                Canyon Suite, USA
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='st-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <div className='sc-pic'>
                              <img src='img/schedule/schedule-4.jpg' alt='' />
                            </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='sc-text'>
                              <h4>Influencing The Influencer</h4>
                              <ul>
                                <li>
                                  <i className='fa fa-user'></i> John Smith
                                </li>
                                <li>
                                  <i className='fa fa-envelope'></i>{" "}
                                  john@Colorlib.com
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <ul className='sc-widget'>
                              <li>
                                <i className='fa fa-clock-o'></i> 08:00 am -
                                10:00 AM
                              </li>
                              <li>
                                <i className='fa fa-map-marker'></i> 59 Breanne
                                Canyon Suite, USA
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='tab-pane' id='tabs-5' role='tabpanel'>
                    <div className='st-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <div className='sc-pic'>
                              <img src='img/schedule/schedule-1.jpg' alt='' />
                            </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='sc-text'>
                              <h4>Dealing with Too Difficult People</h4>
                              <ul>
                                <li>
                                  <i className='fa fa-user'></i> John Smith
                                </li>
                                <li>
                                  <i className='fa fa-envelope'></i>{" "}
                                  john@Colorlib.com
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <ul className='sc-widget'>
                              <li>
                                <i className='fa fa-clock-o'></i> 08:00 am -
                                10:00 AM
                              </li>
                              <li>
                                <i className='fa fa-map-marker'></i> 59 Breanne
                                Canyon Suite, USA
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='st-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <div className='sc-pic'>
                              <img src='img/schedule/schedule-2.jpg' alt='' />
                            </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='sc-text'>
                              <h4>V7 Digital Photo Printing</h4>
                              <ul>
                                <li>
                                  <i className='fa fa-user'></i> John Smith
                                </li>
                                <li>
                                  <i className='fa fa-envelope'></i>{" "}
                                  john@Colorlib.com
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <ul className='sc-widget'>
                              <li>
                                <i className='fa fa-clock-o'></i> 08:00 am -
                                10:00 AM
                              </li>
                              <li>
                                <i className='fa fa-map-marker'></i> 59 Breanne
                                Canyon Suite, USA
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='st-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <div className='sc-pic'>
                              <img src='img/schedule/schedule-3.jpg' alt='' />
                            </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='sc-text'>
                              <h4>Beyond The Naked Eye</h4>
                              <ul>
                                <li>
                                  <i className='fa fa-user'></i> John Smith
                                </li>
                                <li>
                                  <i className='fa fa-envelope'></i>{" "}
                                  john@Colorlib.com
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <ul className='sc-widget'>
                              <li>
                                <i className='fa fa-clock-o'></i> 08:00 am -
                                10:00 AM
                              </li>
                              <li>
                                <i className='fa fa-map-marker'></i> 59 Breanne
                                Canyon Suite, USA
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='st-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <div className='sc-pic'>
                              <img src='img/schedule/schedule-4.jpg' alt='' />
                            </div>
                          </div>
                          <div className='col-lg-5'>
                            <div className='sc-text'>
                              <h4>Influencing The Influencer</h4>
                              <ul>
                                <li>
                                  <i className='fa fa-user'></i> John Smith
                                </li>
                                <li>
                                  <i className='fa fa-envelope'></i>{" "}
                                  john@Colorlib.com
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-lg-4'>
                            <ul className='sc-widget'>
                              <li>
                                <i className='fa fa-clock-o'></i> 08:00 am -
                                10:00 AM
                              </li>
                              <li>
                                <i className='fa fa-map-marker'></i> 59 Breanne
                                Canyon Suite, USA
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className='pricing-section set-bg spad'
        data-setbg='img/pricing-bg.jpg'
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/img/pricing-bg.jpg)`,
        }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='section-title'>
                <h2>Ticket Pricing</h2>
                <p>Get your event ticket plan</p>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-4 col-md-8'>
              <div className='price-item'>
                <h4>Full Pass</h4>
                <div className='pi-price'>
                  <h2>
                    <span>$</span>699
                  </h2>
                </div>
                <ul>
                  <li>Access to all attendees list post the conference</li>
                  <li>Complimentary access to the ILC Networking Chat</li>
                  <li>
                    Conference badges and materials will be distributed to
                    participants
                  </li>
                  <li>
                    An e-Certificate of participation will be issued after the
                    event, upon request
                  </li>
                  <li>Talk to the Editors Session</li>
                </ul>
                <a href='#' className='price-btn'>
                  Get Ticket <span className='arrow_right'></span>
                </a>
              </div>
            </div>
            <div className='col-lg-4 col-md-8'>
              <div className='price-item top-rated'>
                <div className='tr-tag'>
                  <i className='fa fa-star'></i>
                </div>
                <h4>Full Pass</h4>
                <div className='pi-price'>
                  <h2>
                    <span>$</span>599
                  </h2>
                </div>
                <ul>
                  <li>Speaking opportunity in any chosen session</li>
                  <li>
                    Conference badges and materials will be distributed to
                    participants.
                  </li>
                  <li>Complimentary access to the ILC Networking Chat.</li>
                  <li>
                    Registration may be transferred to another colleague within
                    the same organization.
                  </li>
                  <li>Talk to the Editors Session</li>
                  <li>Lunch and Networking</li>
                  <li>Keynote talk</li>
                </ul>
                <a href='#' className='price-btn'>
                  Get Ticket <span className='arrow_right'></span>
                </a>
              </div>
            </div>
            <div className='col-lg-4 col-md-8'>
              <div className='price-item'>
                <h4>Full Pass</h4>
                <div className='pi-price'>
                  <h2>
                    <span>$</span>1999
                  </h2>
                </div>
                <ul>
                  <li>Exhibition Booth</li>
                  <li>Exhibitors marketing benefits</li>
                  <li>All attendees contact list post conference</li>
                  <li>3 delegate passes</li>
                  <li>Complimentary access to the ILC Networking Chat.</li>
                  <li>
                    Marketing benefits like profile will be published in our
                    annual magazine
                  </li>
                </ul>
                <a href='#' className='price-btn'>
                  Get Ticket <span className='arrow_right'></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='newslatter-section'>
        <div className='container'>
          <div
            className='newslatter-inner set-bg'
            data-setbg='img/newslatter-bg.jpg'
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/img/newslatter-bg.jpg)`,
            }}
          >
            <div className='ni-text'>
              <h3>Subscribe Newsletter</h3>
              <p>Subscribe to our newsletter and don’t miss anything</p>
            </div>
            <form action='#' className='ni-form'>
              <input type='text' placeholder='Your email' />
              <button type='submit'>Subscribe</button>
            </form>
          </div>
        </div>
      </section>
      <section className='contact-section spad'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='section-title'>
                <h2>Location</h2>
                <p>Get directions to our event center</p>
              </div>
              <div className='cs-text'>
                <div className='ct-address'>
                  <span>Address:</span>
                  <p>
                    01 Pascale Springs Apt. 339, NY City <br />
                    United State
                  </p>
                </div>
                <ul>
                  <li>
                    <span>Phone:</span>
                    (+12)-345-67-8910
                  </li>
                  <li>
                    <span>Email:</span>
                    info.colorlib@gmail.com
                  </li>
                </ul>
                <div className='ct-links'>
                  <span>Website:</span>
                  <p>https://conference.colorlib.com</p>
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='cs-map'>
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d52901.38789495531!2d-118.19465514866786!3d34.03523211493029!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2cf71ad83ff9f%3A0x518b28657f4543b7!2sEast%20Los%20Angeles%2C%20CA%2C%20USA!5e0!3m2!1sen!2sbd!4v1579763856144!5m2!1sen!2sbd'
                  height='400'
                  style={{ border: "0" }}
                  // allowfullscreen=''
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
