import React from "react";

interface indexInterface {
  // Define your interface properties here
}

const Schedule: React.FC<indexInterface> = () => {
  const sessions = [
    {
      id: 1,
      title:
        "Litigation: Enforcement of Foreign Judgments in the Middle East Reason",
    },
    { id: 2, title: "Does Corporate Matter, Problems with Outsourcing" },
    {
      id: 3,
      title:
        "The Legal Challenges And Disputes That Arise In The Context Of Infrastructure Development Projects",
    },
    {
      id: 4,
      title:
        "The Legal Issues Of The Metaverse And NFTs - The Good, The Brag, And The Ugly",
    },
    {
      id: 5,
      title:
        "Strategies For Managing Litigation Risk, Including Insurance, Litigation Financing, And Early Case Assessment",
    },
    {
      id: 6,
      title:
        "Digital Transformation Technologies Of Legal Proceedings In Civil And Administrative Cases",
    },
    {
      id: 7,
      title:
        "The Use Of AI In Litigation: Legal And Ethical Concerns, For Example Bias In AI Algorithms",
    },
    {
      id: 8,
      title:
        "The Recent Reforms To The Saudi Legal System And Their Impact On Litigation",
    },
    {
      id: 9,
      title:
        "ADR (Alternative Dispute Resolution) And Litigation - Two Different Approaches To Resolving Legal Disputes",
    },
    {
      id: 10,
      title:
        "The Arbitral Process And Alternative Dispute Resolution In The EMEA Region",
    },
    { id: 11, title: "Diversity, Equity, and Inclusion in Litigation" },
    {
      id: 12,
      title: "Future Trends in Commercial and Investment Arbitration",
    },
  ];
  return (
    <div className='row px-28'>
      <div className='col-lg-12'>
        <div className='schedule-tab'>
          <ul className='nav nav-tabs grid grid-cols-2' role='tablist'>
            <li className='nav-item w-full text-center'>
              <a
                className='nav-link active'
                data-toggle='tab'
                href='#tabs-1'
                role='tab'
              >
                <h5>Day 1</h5>
                <p>March 04, 2025</p>
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link'
                data-toggle='tab'
                href='#tabs-2'
                role='tab'
              >
                <h5>Day 2</h5>
                <p>March 05, 2025</p>
              </a>
            </li>
          </ul>
          <div className='tab-content'>
            <div
              className='max-w-6xl mx-auto p-8 bg-gray-100 rounded-lg shadow-lg tab-pane'
              id='tabs-1'
              role='tabpanel'
            >
              <h1 className='text-3xl font-bold text-center mb-8'>
                Agenda 2025 - March
              </h1>
              <table className='min-w-full bg-white rounded-lg'>
                <thead>
                  <tr>
                    <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Session
                    </th>
                    <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Topic
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white'>
                  {sessions.map((session) => (
                    <tr key={session.id} className='hover:bg-gray-100'>
                      <td className='px-6 py-4 border-b border-gray-200 text-sm font-medium text-gray-900'>
                        {`Session ${session.id}`}
                      </td>
                      <td className='px-6 py-4 border-b border-gray-200 text-sm text-gray-700'>
                        {session.title}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              className='max-w-6xl mx-auto p-8 bg-gray-100 rounded-lg shadow-lg tab-pane'
              id='tabs-2'
              role='tabpanel'
            >
              <h1 className='text-3xl font-bold text-center mb-8'>
                Agenda 2025 - March
              </h1>
              <table className='min-w-full bg-white rounded-lg'>
                <thead>
                  <tr>
                    <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Session
                    </th>
                    <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Topic
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white'>
                  {sessions.map((session) => (
                    <tr key={session.id} className='hover:bg-gray-100'>
                      <td className='px-6 py-4 border-b border-gray-200 text-sm font-medium text-gray-900'>
                        {`Session ${session.id}`}
                      </td>
                      <td className='px-6 py-4 border-b border-gray-200 text-sm text-gray-700'>
                        {session.title}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='tab-pane' id='tabs-3' role='tabpanel'>
              <div className='st-content'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-3'>
                      <div className='sc-pic'>
                        <img src='img/schedule/schedule-1.jpg' alt='' />
                      </div>
                    </div>
                    <div className='col-lg-5'>
                      <div className='sc-text'>
                        <h4>Dealing with Intermediate People</h4>
                        <ul>
                          <li>
                            <i className='fa fa-user'></i> John Smith
                          </li>
                          <li>
                            <i className='fa fa-envelope'></i> john@Colorlib.com
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <ul className='sc-widget'>
                        <li>
                          <i className='fa fa-clock-o'></i> 08:00 am - 10:00 AM
                        </li>
                        <li>
                          <i className='fa fa-map-marker'></i> 59 Breanne Canyon
                          Suite, USA
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className='st-content'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-3'>
                      <div className='sc-pic'>
                        <img src='img/schedule/schedule-2.jpg' alt='' />
                      </div>
                    </div>
                    <div className='col-lg-5'>
                      <div className='sc-text'>
                        <h4>V7 Digital Photo Printing</h4>
                        <ul>
                          <li>
                            <i className='fa fa-user'></i> John Smith
                          </li>
                          <li>
                            <i className='fa fa-envelope'></i> john@Colorlib.com
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <ul className='sc-widget'>
                        <li>
                          <i className='fa fa-clock-o'></i> 08:00 am - 10:00 AM
                        </li>
                        <li>
                          <i className='fa fa-map-marker'></i> 59 Breanne Canyon
                          Suite, USA
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className='st-content'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-3'>
                      <div className='sc-pic'>
                        <img src='img/schedule/schedule-3.jpg' alt='' />
                      </div>
                    </div>
                    <div className='col-lg-5'>
                      <div className='sc-text'>
                        <h4>Beyond The Naked Eye</h4>
                        <ul>
                          <li>
                            <i className='fa fa-user'></i> John Smith
                          </li>
                          <li>
                            <i className='fa fa-envelope'></i> john@Colorlib.com
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <ul className='sc-widget'>
                        <li>
                          <i className='fa fa-clock-o'></i> 08:00 am - 10:00 AM
                        </li>
                        <li>
                          <i className='fa fa-map-marker'></i> 59 Breanne Canyon
                          Suite, USA
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className='st-content'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-3'>
                      <div className='sc-pic'>
                        <img src='img/schedule/schedule-4.jpg' alt='' />
                      </div>
                    </div>
                    <div className='col-lg-5'>
                      <div className='sc-text'>
                        <h4>Influencing The Influencer</h4>
                        <ul>
                          <li>
                            <i className='fa fa-user'></i> John Smith
                          </li>
                          <li>
                            <i className='fa fa-envelope'></i> john@Colorlib.com
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <ul className='sc-widget'>
                        <li>
                          <i className='fa fa-clock-o'></i> 08:00 am - 10:00 AM
                        </li>
                        <li>
                          <i className='fa fa-map-marker'></i> 59 Breanne Canyon
                          Suite, USA
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='tab-pane' id='tabs-4' role='tabpanel'>
              <div className='st-content'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-3'>
                      <div className='sc-pic'>
                        <img src='img/schedule/schedule-1.jpg' alt='' />
                      </div>
                    </div>
                    <div className='col-lg-5'>
                      <div className='sc-text'>
                        <h4>Dealing with Expert People</h4>
                        <ul>
                          <li>
                            <i className='fa fa-user'></i> John Smith
                          </li>
                          <li>
                            <i className='fa fa-envelope'></i> john@Colorlib.com
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <ul className='sc-widget'>
                        <li>
                          <i className='fa fa-clock-o'></i> 08:00 am - 10:00 AM
                        </li>
                        <li>
                          <i className='fa fa-map-marker'></i> 59 Breanne Canyon
                          Suite, USA
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className='st-content'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-3'>
                      <div className='sc-pic'>
                        <img src='img/schedule/schedule-2.jpg' alt='' />
                      </div>
                    </div>
                    <div className='col-lg-5'>
                      <div className='sc-text'>
                        <h4>V7 Digital Photo Printing</h4>
                        <ul>
                          <li>
                            <i className='fa fa-user'></i> John Smith
                          </li>
                          <li>
                            <i className='fa fa-envelope'></i> john@Colorlib.com
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <ul className='sc-widget'>
                        <li>
                          <i className='fa fa-clock-o'></i> 08:00 am - 10:00 AM
                        </li>
                        <li>
                          <i className='fa fa-map-marker'></i> 59 Breanne Canyon
                          Suite, USA
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className='st-content'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-3'>
                      <div className='sc-pic'>
                        <img src='img/schedule/schedule-3.jpg' alt='' />
                      </div>
                    </div>
                    <div className='col-lg-5'>
                      <div className='sc-text'>
                        <h4>Beyond The Naked Eye</h4>
                        <ul>
                          <li>
                            <i className='fa fa-user'></i> John Smith
                          </li>
                          <li>
                            <i className='fa fa-envelope'></i> john@Colorlib.com
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <ul className='sc-widget'>
                        <li>
                          <i className='fa fa-clock-o'></i> 08:00 am - 10:00 AM
                        </li>
                        <li>
                          <i className='fa fa-map-marker'></i> 59 Breanne Canyon
                          Suite, USA
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className='st-content'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-3'>
                      <div className='sc-pic'>
                        <img src='img/schedule/schedule-4.jpg' alt='' />
                      </div>
                    </div>
                    <div className='col-lg-5'>
                      <div className='sc-text'>
                        <h4>Influencing The Influencer</h4>
                        <ul>
                          <li>
                            <i className='fa fa-user'></i> John Smith
                          </li>
                          <li>
                            <i className='fa fa-envelope'></i> john@Colorlib.com
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <ul className='sc-widget'>
                        <li>
                          <i className='fa fa-clock-o'></i> 08:00 am - 10:00 AM
                        </li>
                        <li>
                          <i className='fa fa-map-marker'></i> 59 Breanne Canyon
                          Suite, USA
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='tab-pane' id='tabs-5' role='tabpanel'>
              <div className='st-content'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-3'>
                      <div className='sc-pic'>
                        <img src='img/schedule/schedule-1.jpg' alt='' />
                      </div>
                    </div>
                    <div className='col-lg-5'>
                      <div className='sc-text'>
                        <h4>Dealing with Too Difficult People</h4>
                        <ul>
                          <li>
                            <i className='fa fa-user'></i> John Smith
                          </li>
                          <li>
                            <i className='fa fa-envelope'></i> john@Colorlib.com
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <ul className='sc-widget'>
                        <li>
                          <i className='fa fa-clock-o'></i> 08:00 am - 10:00 AM
                        </li>
                        <li>
                          <i className='fa fa-map-marker'></i> 59 Breanne Canyon
                          Suite, USA
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className='st-content'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-3'>
                      <div className='sc-pic'>
                        <img src='img/schedule/schedule-2.jpg' alt='' />
                      </div>
                    </div>
                    <div className='col-lg-5'>
                      <div className='sc-text'>
                        <h4>V7 Digital Photo Printing</h4>
                        <ul>
                          <li>
                            <i className='fa fa-user'></i> John Smith
                          </li>
                          <li>
                            <i className='fa fa-envelope'></i> john@Colorlib.com
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <ul className='sc-widget'>
                        <li>
                          <i className='fa fa-clock-o'></i> 08:00 am - 10:00 AM
                        </li>
                        <li>
                          <i className='fa fa-map-marker'></i> 59 Breanne Canyon
                          Suite, USA
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className='st-content'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-3'>
                      <div className='sc-pic'>
                        <img src='img/schedule/schedule-3.jpg' alt='' />
                      </div>
                    </div>
                    <div className='col-lg-5'>
                      <div className='sc-text'>
                        <h4>Beyond The Naked Eye</h4>
                        <ul>
                          <li>
                            <i className='fa fa-user'></i> John Smith
                          </li>
                          <li>
                            <i className='fa fa-envelope'></i> john@Colorlib.com
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <ul className='sc-widget'>
                        <li>
                          <i className='fa fa-clock-o'></i> 08:00 am - 10:00 AM
                        </li>
                        <li>
                          <i className='fa fa-map-marker'></i> 59 Breanne Canyon
                          Suite, USA
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className='st-content'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-3'>
                      <div className='sc-pic'>
                        <img src='img/schedule/schedule-4.jpg' alt='' />
                      </div>
                    </div>
                    <div className='col-lg-5'>
                      <div className='sc-text'>
                        <h4>Influencing The Influencer</h4>
                        <ul>
                          <li>
                            <i className='fa fa-user'></i> John Smith
                          </li>
                          <li>
                            <i className='fa fa-envelope'></i> john@Colorlib.com
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <ul className='sc-widget'>
                        <li>
                          <i className='fa fa-clock-o'></i> 08:00 am - 10:00 AM
                        </li>
                        <li>
                          <i className='fa fa-map-marker'></i> 59 Breanne Canyon
                          Suite, USA
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
